import { gql } from 'apollo-boost'

const GET_SESSION = gql`
  query session {
    session {
      _id
      firstname
      lastname
      role
    }
  }
`

export { GET_SESSION }
