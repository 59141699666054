import { gql } from 'apollo-boost'

const UPDATE_PATIENT = gql`
  mutation updatePatient($_id: ID!, $input: PatientUpdateInput) {
    updatePatient(_id: $_id, input: $input) {
      _id
      rut
      passport
      firstname
      lastname
      birthdate
    }
  }
`

export { UPDATE_PATIENT }
