import React from 'react'
import Root from './Root'
import Screens from './Screens'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ApolloApp, AblyProvider } from './Providers'
import { Client } from './ApolloClient'
import { ConfigProvider } from 'antd'
import 'moment/locale/es'
import './index.css'
import 'typeface-roboto'
import 'typeface-inter'
import esES from 'antd/es/locale/es_ES'
import dayjs from 'dayjs'
dayjs.locale('es')

const propTypes = {}
const defaultProps = {}

const App = () => {
  return (
    <ConfigProvider locale={esES}>
      <ApolloApp client={Client}>
        <AblyProvider>
          <Router>
            <Route component={Root}>{Screens}</Route>
          </Router>
        </AblyProvider>
      </ApolloApp>
    </ConfigProvider>
  )
}

App.propTypes = propTypes
App.defaultProps = defaultProps

export default App
