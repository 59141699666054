import React, { useState, useEffect, useLayoutEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import { Auth } from 'aws-amplify'
import Header from './Header'
import getWindowDimensions from 'App/Helpers/windowResize'
const { Content } = Layout

const AppLayout = ({ history, children }) => {

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

    useLayoutEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    }, [])

  useEffect(() => {
    Auth.currentSession()
      .then(data => {})
      .catch(err => {
        console.log(err)
        return history.push('/login')
      })
  }, [history])

  return (
    <Layout>
      <Row
        justify="space-around"
        align="middle">
        <Col md={24}>
          <Row style={{}} justify="space-around" align="middle">
            <Col md={24}>
              <Layout style={{ backgroundColor: 'white' }}>
                <Header windowDimensions={windowDimensions}/>
                <Content>{children}</Content>
              </Layout>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default withRouter(AppLayout)
