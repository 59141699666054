import React, { useMemo } from 'react'
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import UserMenu from './UserMenu'
import { useQuery } from '@apollo/react-hooks'
import { GET_USER } from 'App/Queries'
const propTypes = {}

const defaultProps = {}

const UserDropDown = () => {
  const { loading, data } = useQuery(GET_USER)
  const username = useMemo(() => (
    loading ? '' : `${data.session.firstname} ${data.session.lastname}`),
    [loading, data],
  )

  return (
    <Dropdown overlay={<UserMenu />}>
      <span
        style={{
          color: 'rgba(89,116,175,1)',
          fontWeight: 'bold',
          marginRight: '20px',
          cursor: 'pointer',
        }}>
        {username} <DownOutlined />
      </span>
    </Dropdown>
  )
}

UserDropDown.propTypes = propTypes
UserDropDown.defaultProps = defaultProps

export default UserDropDown
