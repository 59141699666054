import React from 'react'
import { Table } from 'antd'
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons'

const InternalAppointmentsTable = ({ internalAppointments, loading, showEditConfirm, showDeleteConfirm }) => {
  const columns = [
    {
      title: 'Profesional Médico CLC',
      dataIndex: 'doctorName'
    },
    {
      title: 'Especialidad',
      dataIndex: 'departmentName'
    },
    {
      title: 'Clinica Externa',
      dataIndex: 'externalHospitalName'
    },
    {
      title: 'Profesional Médico Externo',
      dataIndex: 'externalDoctorName'
    },
    {
      title: 'Hora',
      dataIndex: 'scheduledAt'
    },
    {
      title: 'Acción',
      width: '50',
      render: (_, internalAppointment) => (
        <div>
          <EditTwoTone
            onClick={() => showEditConfirm(internalAppointment)}
            twoToneColor="#E0A800"
          />
          <DeleteTwoTone
            style={{ marginLeft: '10%' }}
            onClick={() => showDeleteConfirm(internalAppointment)}
            twoToneColor="#F5222D"
          />
        </div>
      ),
    },
  ]
  // console.log('internalAppointments tableee', internalAppointments)y
  return (
    <Table
      bordered
      dataSource={internalAppointments}
      columns={columns}
      loading={loading}
      scroll={{ y: 380 }}
    />
  )
}

export default InternalAppointmentsTable
