import React from 'react'
import { Row, Col } from 'antd'
import { withRouter } from 'react-router-dom'
import Logo from 'App/Assets/SVG/Logo'
import UserDropdown from './UserDropDown'


const Header = ({ windowDimensions }) => {

  return (
    <div style={{ padding: '0px 50px' }}>
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <Logo width={windowDimensions.width * 0.12} style={{ padding: '1.5vh 0vh' }} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <UserDropdown />
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(Header)
