import React from 'react'

import {
    Button
} from 'antd'

import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined'

const InputFile = props => {

    return (
        <Button  {...props} type="primary" size="small" className="buttonFile" icon={<PaperClipOutlined />} />
    )

}

export default InputFile