import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

const propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
}

const defaultProps = {}

const Status = ({ connectionStatus, color, label }) => {
  const alphaColor = useMemo(() => `${color}66`, [color])
  return (
    <div
      className={styles.container}
      style={{
        borderColor:
          connectionStatus !== 'done' ? alphaColor : 'rgba(89,116,175)',
        color,
        flex: 1,
      }}>
      <div
        className={styles.outerDot}
        style={{
          backgroundColor:
            connectionStatus !== 'done' ? alphaColor : 'rgba(0,0,0,0)',
        }}>
        <div className={styles.innerDot} style={{ backgroundColor: color }} />
      </div>
      <label style={{ marginLeft: '0.5vw' }}>{label}</label>
    </div>
  )
}

Status.propTypes = propTypes
Status.defaultProps = defaultProps

export default Status
