import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MainScreen from './MainScreen'
import CreateScreen from './CreateScreen'

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={MainScreen} />
      <Route path={`${match.path}/create`} component={CreateScreen} />
    </Switch>
  )
}
