/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect, useCallback } from 'react'
import Ably from 'ably/promises'

export const AblyContext = createContext({
  realtime: null,
  setRealtime: () => { },
})

export const AblyProvider = ({ children }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRealtime = useCallback(realtime => {
    // eslint-disable-next-line no-use-before-define
    setState({ ...state, realtime })
  })

  const initState = {
    realtime: null,
    setRealtime,
  }

  const [state, setState] = useState(initState)

  // these lines are commented because eventually we will need ably

  // useEffect(() => {
  //   setRealtime(new Ably.Realtime.Promise(process.env.REACT_APP_ABLY_API_KEY))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return <AblyContext.Provider value={state}>{children}</AblyContext.Provider>
}
