import React from 'react'

import AppointmentItem from 'App/Screens/Appointments/MainScreen/components/AppointmentItem'

const defaultProps = {}

const CallStatus = ({ appointment }) => {
  return (
    <>
      <AppointmentItem appointment={appointment} />
    </>
  )
}

CallStatus.defaultProps = defaultProps

export default CallStatus
