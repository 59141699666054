import { gql } from 'apollo-boost'

const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment($input: AppointmentCreateInput!) {
    createAppointment(input: $input) {
      _id
    }
  }
`

export { CREATE_APPOINTMENT }
