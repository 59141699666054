import dayjs from 'dayjs'

export default response => {
  try {
    const { allAppointments } = response
    const appointments = allAppointments.map(appointment => {
      const { _id, patient, professional, scheduledAt } = appointment
      return {
        key: _id,
        token: appointment.token,
        doctor: `${professional.firstname} ${professional.lastname}`,
        doctor_id: `${professional._id}`,
        closedAt: `${appointment.closedAt}`,
        patient: {
          _id: patient._id,
          document: patient.rut === null ? patient.passport : patient.rut,
          name: `${patient.firstname} ${patient.lastname}`,
        },
        scheduledAt: dayjs(scheduledAt).format('DD/MM/YYYY HH:mm'),
      }
    })
    return appointments
  } catch (error) {
    console.log(error)
    return []
  }
}
