import { gql } from 'apollo-boost'

const CREATE_PATIENT = gql`
  mutation createPatient($input: PatientCreateInput) {
    createPatient(input: $input) {
      _id
      rut
      passport
      firstname
      lastname
      birthdate
    }
  }
`

export { CREATE_PATIENT }
