import React, { useState, useEffect } from 'react'

import StatusIndicator from 'App/Components/StatusIndicator'

const status = {
  done: {
    color: 'rgba(89,116,175)',
    label: 'Finalizado',
  },
  notDone: {
    color: '#979797',
    label: 'No Finalizada',
  },
}

const AppointmentItem = ({ appointment }) => {
  const [connectionStatus, setConnectionStatus] = useState('notDone')
  useEffect(() => {
    if (appointment.closedAt === 'null') setConnectionStatus('notDone')
    else setConnectionStatus('done')
  }, [appointment])

  return (
    <StatusIndicator
      connectionStatus={connectionStatus}
      color={status[connectionStatus].color}
      label={status[connectionStatus].label}
    />
  )
}

export default AppointmentItem
