import React, { useState, useEffect } from 'react'
import AppointmentsTable from './components/AppointmentsTable'
import parseQueryResponse from 'App/Helpers/parseQueryResponse'
import dayjs from 'dayjs'
import localization from 'dayjs/locale/es'
import { withRouter } from 'react-router-dom'
import { Button, Typography, Row, Col, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_APPOINTMENTS } from 'App/Queries'
import { DELETE_APPOINTMENT } from 'App/Mutators'
const { Text, Title } = Typography
const { confirm } = Modal

const HomeScreen = ({ history }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [deleteId, setDeleteId] = useState(null)
  const { loading, data: appointments, refetch: refetchGetAppointments } = useQuery(GET_APPOINTMENTS, {
    variables: {
      from: selectedDate.startOf('day').format(),
      to: selectedDate.endOf('day').format()
    }
  })
  const [deleteAppointmentMutator, { error: deleteError }] = useMutation(DELETE_APPOINTMENT, { variables: { _id: deleteId } })

  const nextDay = () => setSelectedDate(selectedDate.add(1, 'day'))

  const previousDay = () => setSelectedDate(selectedDate.subtract(1, 'day'))

  const deleteAppointment = async () => {
    await deleteAppointmentMutator()
    if (deleteError) {
      console.log(deleteError)
      return
    }
    await refetchGetAppointments()
  }

  useEffect(() => {
    refetchGetAppointments()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showDeleteConfirm = appointment => {
    setDeleteId(appointment.key)
    const { doctor, patient, scheduledAt } = appointment
    confirm({
      title: '¿Quieres eliminar esta consulta?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text strong>Médico</Text>
          <Text>: {doctor}</Text>
          <br />
          <Text strong>Nombre Paciente</Text>
          <Text>: {patient.name}</Text>
          <br />
          <Text strong>Rut Paciente</Text>
          <Text>: {patient.rut}</Text>
          <br />
          <Text strong>Fecha</Text>
          <Text>: {scheduledAt}</Text>
          <br />
          <Text strong>
            Esta consulta será eliminada y tanto el paciente como el médico
            serán notificados.
          </Text>
        </>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAppointment()
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  return (
    <>
      <Row style={{ marginLeft: '10%', marginRight: '10%' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            onClick={() => history.push('/appointments/create')}>
            CREAR NUEVA CONSULTA
          </Button>
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginLeft: '10%', marginRight: '10%' }}>
        <Col span={12}>
          <Title level={3}>
            Hoy {selectedDate.locale('es', localization).format('dddd, D [de] MMMM')}
          </Title>
        </Col>
        <Col span={{ md: 6 }} style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 20 }}
            onClick={previousDay}>
            Día anterior
          </Button>
          <Button
            onClick={nextDay}>
            Día siguiente
          </Button>
        </Col>
      </Row>

      <Row style={{ marginLeft: '10%', marginRight: '10%', marginTop: '2%' }}>
        <Col span={24}>
          <AppointmentsTable
            appointments={loading ? [] : parseQueryResponse(appointments)}
            showDeleteConfirm={showDeleteConfirm}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  )
}
export default withRouter(HomeScreen)
