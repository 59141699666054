/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { loader } from 'graphql.macro'
import MenuInternalAppointments from 'App/Screens/InternalAppointments/MainScreen/components/MenuInternalAppointments'
import dayjs from 'dayjs'
import {
  Button,
  Row,
  Col,
  Form,
  Typography,
  Input,
  notification,
  Select,
  DatePicker,
  TimePicker,
  Upload,
  message,
} from 'antd'
import moment from 'moment'

import InputFile from './Components/InputFile'
import ProfessionalsList from './Components/ProfessionalsList'
import AttentionModules from './Components/AttentionModules'
import Specialities from './Components/Specialities'

import LeftOutlined from '@ant-design/icons/LeftOutlined'

import './CreateScreen.css'

import getGraphQLClient from 'App/Helpers/getGraphQLClient'
import { request } from 'graphql-request'
import parseRut from 'App/Helpers/parseRut'
import validateJustInt from 'App/Helpers/validateJustInt'
import GetActualDateString from 'App/Helpers/GetActualDateString'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_PROFESSIONALS, FIND_PATIENT } from 'App/Queries'
import { CREATE_APPOINTMENT, CREATE_PATIENT, UPDATE_PATIENT } from 'App/Mutators'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

const openNotificationWithIcon = type => {
  const message = {
    success: 'Éxito',
    error: 'Error',
  }

  const description = {
    success: 'Los datos han sido ingresados correctamente',
    error: 'Ha ocurrido un error en el ingreso. Inténtelo nuevamente',
  }

  notification[type]({
    message: message[type],
    description: description[type],
    duration: 3,
    onClick: () => {
      console.log('Notification Clicked!')
    },
  })
}

const parseQueryResponse = response => {
  const { allProfessionals } = response
  const professionals = allProfessionals.map(professional => {
    const { _id, firstname, lastname } = professional
    return {
      value: _id,
      label: `${firstname} ${lastname}`,
    }
  })
  return professionals
}

const buildAppointmentInput = values => {
  const { date, hour, professional, patient } = values
  return {
    input: {
      patient,
      professional,
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
      hour: hour.hour(),
      minute: hour.minute(),
    },
  }
}

const buildUpdatePatientInput = (_id, values) => {
  const { rut, passport, firstname, lastname, birthdate, email, phone } = values
  return {
    _id,
    input: {
      rut,
      passport,
      firstname,
      lastname,
      birthdate,
      email,
      phone,
    },
  }
}

const buildCreatePatientInput = values => {
  const { rut, passport, firstname, lastname, birthdate, email, phone } = values
  return {
    input: {
      rut,
      passport,
      firstname,
      lastname,
      birthdate,
      email,
      phone,
    },
  }
}

const CreateScreen = ({ history }) => {

  const [form] = Form.useForm()
  const [professionals, setProfessionals] = useState([])
  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState(null)
  const [typeOfRutOrPassport, setTypeOfRutOrPassport] = useState(true)
  const [inputFilesValue, setInputFilesValue] = useState('')
  const [rut, setRut] = useState('')
  const [passport, setPassport] = useState('')
  const { data: professionalsData } = useQuery(GET_PROFESSIONALS)
  const { refetch: refetchFindPatient } = useQuery(FIND_PATIENT, { variables: { rut, passport } })
  const [createPatient] = useMutation(CREATE_PATIENT)
  const [updatePatient] = useMutation(UPDATE_PATIENT)
  const [createAppointment] = useMutation(CREATE_APPOINTMENT)

  const [files, setFiles] = useState(null)

  const attentionModules = [
    {
      'value': '0',
      'label': 'módulo 1'
    },
    {
      'value': '1',
      'label': 'módulo 2'
    },
    {
      'value': '2',
      'label': 'módulo 3'
    },
  ]

  const specialities = [
    {
      'value': '0',
      'label': 'especialidad 1'
    },
    {
      'value': '1',
      'label': 'especialidad 2'
    },
    {
      'value': '2',
      'label': 'especialidad 3'
    },
  ]

  const inputFileRef = useRef()

  const onFinish = async values => {
    console.log(values)
    setLoading(true)
    openNotificationWithIcon('success')
    setLoading(false)
    history.push('/internal-appointments')
    const graphQLClient = await getGraphQLClient()
    let updatedPatient
    if (!patient)
      try {
        const response = await graphQLClient.request(
          createPatient,
          buildCreatePatientInput(values),
        )
        updatedPatient = response.createPatient
        notification.open({
          message: 'Paciente registrado',
          description: 'Los datos fueron guardados.',
          duration: 5,
        })
      } catch (error) {
        const err = error.toString()
        if (err.includes('Validation failed: phone'))
          message.info('Formato celular incorrecto. Ej. +56912345678')
        else if (err.includes('validation failed: rut'))
          message.info('Rut inválido, inténtelo de nuevo')
        openNotificationWithIcon('error')
        setLoading(false)
      }
    else
      try {
        const response = await graphQLClient.request(
          updatePatient,
          buildUpdatePatientInput(patient._id, values),
        )
        updatedPatient = response.updatePatient
        notification.open({
          message: 'Paciente actualizado',
          description: 'Los datos fueron actualizados.',
          duration: 5,
        })
      } catch (error) {
        const err = error.toString()
        if (err.includes('Validation failed: phone'))
          message.info('Formato celular incorrecto. Ej. +56912345678')
        else if (err.includes('validation failed: rut'))
          message.info('Rut inválido, inténtelo de nuevo')
        openNotificationWithIcon('error')
        setLoading(false)
      }
    if (updatedPatient !== undefined) {
      const newAppointment = buildAppointmentInput({
        ...values,
        patient: updatedPatient._id,
      })
      try {
        await graphQLClient.request(createAppointment, newAppointment)
        openNotificationWithIcon('success')
        history.push('/appointments')
      } catch (error) {
        console.log(error.toString())
        const err = error.toString()
        if (err.includes('Validation failed: phone'))
          message.info('Formato celular incorrecto. Ej. +56912345678')
        else if (err.includes('validation failed: rut'))
          message.info('Rut inválido, inténtelo de nuevo')
        setLoading(false)
        openNotificationWithIcon('error')
      }
    }
  }

  const getProfessionals = async () => {
    const graphQLClient = await getGraphQLClient()
    try {
      const response = await graphQLClient.request(getProfessionals)
      const professionals = parseQueryResponse(response)
      setProfessionals(professionals)
    } catch (error) {
      console.error(error)
    }
  }

  const getPatient = async (rut, passport) => {
    const graphQLClient = await getGraphQLClient()
    let findPatient
    try {
      const response = await graphQLClient.request(findPatient, {
        rut,
        passport,
      })
      const patient = response.findPatient

      setPatient(patient)
      form.setFieldsValue({
        ...patient,
        birthdate: moment(patient.birthdate),
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getProfessionals()
  }, [])

  function disabledDate(current) {
    return (
      current < moment().startOf('day') ||
      current >
      moment()
        .add(90, 'day')
        .endOf('day')
    )
  }

  const validateMessages = {
    required: 'Este campo es requerido!',
    types: {
      email: 'Formato de correo inválido!',
    },
  }

  const uploadProps = {
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') console.log(file, fileList)
      console.log(file, fileList)
    }
  }

  const triggerInputFileClick = e => {
    inputFileRef.current.click()
  }

  const getSelectedFile = e => {
    setFiles(e.target.files)
    console.log(form.setFieldsValue({
      file: e.target.files[0].name
    }))
  }

  useEffect(() => {
    console.log(files)
  }, [files])

  return (
    <>
      <MenuInternalAppointments
        selectedDate={dayjs()}
        history={history}
      />
      <Row justify="center">
        <Col span={17} className="createAppointmentTitle">
          <Title level={4}>
            Ingrese los Datos para la Interconsulta de emergencia
          </Title>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={17}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateMessages={validateMessages}>

            <Row>

              <input type="file" ref={inputFileRef} style={{ display: 'none' }} onChange={event => getSelectedFile(event)} />

              {/* Left column */}
              <Col span={12}>

                <Row>

                  <Col span={23}>
                    <Specialities specialities={specialities} />
                  </Col>

                  <Col span={23}>
                    <Form.Item name="hourConsult" rules={[{ required: true }]}>
                      <TimePicker
                        format={'HH:mm'}
                        minuteStep={15}
                        placeholder="Hora"
                        style={{ width: '100%' }}
                        onChange={() => { }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={23}>
                    <ProfessionalsList professionals={professionals} />
                  </Col>

                </Row>

              </Col>

              {/* Right column */}
              <Col span={12}>

                <Row>

                  <Col span={23}>
                    <AttentionModules attentionModules={attentionModules} />
                  </Col>

                  <Col span={23}>
                    <Form.Item name="pacientName" rules={[{ required: true }]}>
                      <Input placeholder="Nombre del paciente" />
                    </Form.Item>
                  </Col>

                  <Col span={23}>
                    <Form.Item name="description" rules={[{ required: true }]}>
                      <TextArea rows={4} placeholder="Descripción del problema o motivos de la consulta" />
                    </Form.Item>
                  </Col>

                  <Col span={23}>
                    <Form.Item name="file" rules={[{ required: true }]}>
                      <Input
                        placeholder="Agregar archivos Adjuntos."
                        readOnly
                        prefix={<InputFile onClick={() => triggerInputFileClick()} />}
                      />
                    </Form.Item>
                  </Col>

                </Row>

              </Col>

            </Row>

            <Row>

              <Col span={23} align="right">
                <Form.Item>
                  <Button type="primary" shape="round" htmlType="submit">
                    CREAR CONSULTA
                    </Button>
                </Form.Item>
              </Col>

            </Row>

          </Form>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(CreateScreen)
