import React, { useState, useEffect } from 'react'
import { Button, Typography, Row, Col } from 'antd'
import { CalendarTwoTone, RollbackOutlined } from '@ant-design/icons'

// eslint-disable-next-line no-unused-vars
import dayjs from 'dayjs'
import localization from 'dayjs/locale/es'

const { Title } = Typography

const MenuInternalAppointments = ({ previousDay, nextDay, selectedDate, history }) => {
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    if (history.location.pathname === '/internal-appointments/create')
      setIsAdding(true)
    else
      setIsAdding(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdding])

  const handleClick = () => {
    history.push('/internal-appointments/create')
    setIsAdding(true)
  }

  const handleClickComeBack = () => {
    history.push('/internal-appointments')
    setIsAdding(false)
  }

  return (
    <div style={{ backgroundColor: '#F9F9F9', padding: '10px 50px' }}>
      <Row justify='space-between' align='middle'>
        <Col span={20}>
          <Title level={1} style={{ color: '#6B7CFF', margin: '0', textTransform: 'uppercase' }}>Portal de coordinadores interconsultas</Title>
          <Title level={3}><CalendarTwoTone twoToneColor="#7C91B3" /> Hoy {selectedDate.locale('es', localization).format('dddd, D [de] MMMM')}</Title>
        </Col>
        <Col span={4} style={{ display: 'flex', flexDirection: 'column' }}>
          {
            !isAdding
              ?
              (
                <>
                  <Row>
                    <Button
                      type="primary"
                      shape="round"
                      style={{ minHeight: '50px' }}
                      onClick={handleClick}>
                      AGENDA NUEVA CONSULTA
                </Button>
                  </Row>
                  <Row style={{ marginTop: '1vh' }}>
                    <Button
                      // style={{ marginRight: 20 }}
                      shape="round"
                      onClick={previousDay}>
                      Día anterior
                  </Button>
                    <Button
                      shape="round"
                      onClick={nextDay}>
                      Día siguiente
                  </Button>
                  </Row>
                </>
              )
              :
              (
                <Row>
                  <Button
                    type='secondary'
                    shape='round'
                    style={{ minHeight: '50px', minWidth: '134px', border: '3px solid #6B7CFF', color: '#6B7CFF', fontSize: '15px', fontWeight: 'bold' }}
                    onClick={handleClickComeBack}
                  >
                    <RollbackOutlined />
                    VOLVER ATRAS
                  </Button>
                </Row>
              )
          }
        </Col>
      </Row>
    </div>
  )
}

export default MenuInternalAppointments