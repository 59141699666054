import React from 'react'
import { Table } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import CallStatus from './CallStatus'

const AppointmentsTable = ({ appointments, loading, showDeleteConfirm }) => {
  const columns = [
    {
      title: 'Médico',
      dataIndex: 'doctor',
      key: 'doctor',
    },
    {
      title: 'Nombre Paciente',
      dataIndex: ['patient', 'name'],
      key: 'patient-name',
    },
    {
      title: 'Rut/Pasaporte Paciente',
      dataIndex: ['patient', 'document'],
      key: 'patient-rut',
    },
    {
      title: 'Horario',
      dataIndex: 'scheduledAt',
      key: 'scheduledAt',
    },
    {
      title: 'Estado Llamada',
      key: 'state',
      render: (_, appointment) => (
        <CallStatus appointment={appointment} />
      ),
    },
    {
      title: 'Eliminar',
      key: 'action',
      width: '50',
      render: (_, appointment) => (
        <DeleteTwoTone
          onClick={() => showDeleteConfirm(appointment)}
          twoToneColor="#F5222D"
        />
      ),
    },
  ]
  return (
    <>
      <Table
        bordered
        dataSource={appointments}
        columns={columns}
        loading={loading}
        scroll={{ y: 380 }}
      />
    </>
  )
}

export default AppointmentsTable
