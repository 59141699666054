import { gql } from 'apollo-boost'

const FIND_PATIENT = gql`
  query FindPatient($rut: String, $passport: String) {
    findPatient(rut: $rut, passport: $passport) {
      _id
      rut
      passport
      email
      firstname
      lastname
      phone
      birthdate
    }
  }
`

export { FIND_PATIENT }
