import Auth from '@aws-amplify/auth'

export default async () => {
  const user = await Auth.currentAuthenticatedUser()
  // console.log(`Bearer ${user.signInUserSession.idToken.jwtToken}`)

  return {
    Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
  }
}
