import React from 'react'

import {
    Select,
    Form
} from 'antd'

const { Option } = Select

const AttentionModules = ({ attentionModules }) => {
    return (
        <>
            <Form.Item name="atentionModule" rules={[{ required: true }]}>
                <Select defaultValue="" style={{ width: '100%' }} onChange={value => {
                    console.log(value)
                }}>
                    <Option value="">Seleccione Módulo de Atención</Option>
                    {
                        attentionModules.map(({ value, label }) => {
                            return (
                                <Option key={value} value={value}>{label}</Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </>
    )
}

export default AttentionModules