import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Row, Col, Modal, Input, TimePicker, notification } from 'antd'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import MenuInternalAppointments from './components/MenuInternalAppointments'

import dayjs from 'dayjs'

import InternalAppointmentsTable from './components/InternalAppointmentsTable'


const { Text } = Typography
const { confirm } = Modal

const HomeScreen = ({ history }) => {
  const mockData01 = [
    { key: 1, doctorName: 'Doctor A', departmentName: 'Departamento A', externalHospitalName: 'Clínica A', externalDoctorName: 'Doctor externo A', scheduledAt: '07:00' },
    { key: 2, doctorName: 'Doctor B', departmentName: 'Departamento B', externalHospitalName: 'Clínica B', externalDoctorName: 'Doctor externo B', scheduledAt: '07:00' },
    { key: 3, doctorName: 'Doctor C', departmentName: 'Departamento C', externalHospitalName: 'Clínica C', externalDoctorName: 'Doctor externo C', scheduledAt: '07:00' },
    { key: 4, doctorName: 'Doctor D', departmentName: 'Departamento D', externalHospitalName: 'Clínica D', externalDoctorName: 'Doctor externo D', scheduledAt: '07:00' },
    { key: 5, doctorName: 'Doctor E', departmentName: 'Departamento E', externalHospitalName: 'Clínica E', externalDoctorName: 'Doctor externo E', scheduledAt: '07:00' },
    { key: 6, doctorName: 'Doctor F', departmentName: 'Departamento F', externalHospitalName: 'Clínica F', externalDoctorName: 'Doctor externo F', scheduledAt: '07:00' },
    { key: 7, doctorName: 'Doctor G', departmentName: 'Departamento G', externalHospitalName: 'Clínica G', externalDoctorName: 'Doctor externo G', scheduledAt: '07:00' },
    { key: 8, doctorName: 'Doctor H', departmentName: 'Departamento H', externalHospitalName: 'Clínica H', externalDoctorName: 'Doctor externo H', scheduledAt: '07:00' },
    { key: 9, doctorName: 'Doctor I', departmentName: 'Departamento I', externalHospitalName: 'Clínica I', externalDoctorName: 'Doctor externo I', scheduledAt: '07:00' },
    { key: 10, doctorName: 'Doctor J', departmentName: 'Departamento J', externalHospitalName: 'Clínica J', externalDoctorName: 'Doctor externo J', scheduledAt: '07:00' },
    { key: 11, doctorName: 'Doctor K', departmentName: 'Departamento K', externalHospitalName: 'Clínica K', externalDoctorName: 'Doctor externo K', scheduledAt: '07:00' },
    { key: 12, doctorName: 'Doctor L', departmentName: 'Departamento L', externalHospitalName: 'Clínica L', externalDoctorName: 'Doctor externo L', scheduledAt: '07:00' },
    { key: 13, doctorName: 'Doctor M', departmentName: 'Departamento M', externalHospitalName: 'Clínica M', externalDoctorName: 'Doctor externo M', scheduledAt: '07:00' },
    { key: 14, doctorName: 'Doctor N', departmentName: 'Departamento N', externalHospitalName: 'Clínica N', externalDoctorName: 'Doctor externo N', scheduledAt: '07:00' },
    { key: 15, doctorName: 'Doctor Ñ', departmentName: 'Departamento Ñ', externalHospitalName: 'Clínica Ñ', externalDoctorName: 'Doctor externo Ñ', scheduledAt: '07:00' },
  ]
  const mockData02 = [
    { key: 1, doctorName: 'Doctor O', departmentName: 'Departamento O', externalHospitalName: 'Clínica O', externalDoctorName: 'Doctor externo O', scheduledAt: '08:00' },
    { key: 2, doctorName: 'Doctor P', departmentName: 'Departamento P', externalHospitalName: 'Clínica P', externalDoctorName: 'Doctor externo P', scheduledAt: '08:00' },
    { key: 3, doctorName: 'Doctor Q', departmentName: 'Departamento Q', externalHospitalName: 'Clínica Q', externalDoctorName: 'Doctor externo Q', scheduledAt: '08:00' },
    { key: 4, doctorName: 'Doctor R', departmentName: 'Departamento R', externalHospitalName: 'Clínica R', externalDoctorName: 'Doctor externo R', scheduledAt: '08:00' },
    { key: 5, doctorName: 'Doctor S', departmentName: 'Departamento S', externalHospitalName: 'Clínica S', externalDoctorName: 'Doctor externo S', scheduledAt: '08:00' },
    { key: 6, doctorName: 'Doctor T', departmentName: 'Departamento T', externalHospitalName: 'Clínica T', externalDoctorName: 'Doctor externo T', scheduledAt: '08:00' },
    { key: 7, doctorName: 'Doctor U', departmentName: 'Departamento U', externalHospitalName: 'Clínica U', externalDoctorName: 'Doctor externo U', scheduledAt: '08:00' },
    { key: 8, doctorName: 'Doctor V', departmentName: 'Departamento V', externalHospitalName: 'Clínica V', externalDoctorName: 'Doctor externo V', scheduledAt: '08:00' },
    { key: 9, doctorName: 'Doctor W', departmentName: 'Departamento W', externalHospitalName: 'Clínica W', externalDoctorName: 'Doctor externo W', scheduledAt: '08:00' },
    { key: 10, doctorName: 'Doctor X', departmentName: 'Departamento X', externalHospitalName: 'Clínica X', externalDoctorName: 'Doctor externo X', scheduledAt: '08:00' },
    { key: 11, doctorName: 'Doctor Y', departmentName: 'Departamento Y', externalHospitalName: 'Clínica Y', externalDoctorName: 'Doctor externo Y', scheduledAt: '08:00' },
    { key: 12, doctorName: 'Doctor Z', departmentName: 'Departamento Z', externalHospitalName: 'Clínica Z', externalDoctorName: 'Doctor externo Z', scheduledAt: '08:00' },
    { key: 13, doctorName: 'Doctor A', departmentName: 'Departamento A', externalHospitalName: 'Clínica A', externalDoctorName: 'Doctor externo A', scheduledAt: '08:00' },
    { key: 14, doctorName: 'Doctor B', departmentName: 'Departamento B', externalHospitalName: 'Clínica B', externalDoctorName: 'Doctor externo B', scheduledAt: '08:00' },
    { key: 15, doctorName: 'Doctor C', departmentName: 'Departamento C', externalHospitalName: 'Clínica C', externalDoctorName: 'Doctor externo C', scheduledAt: '08:00' },
  ]
  const mockData03 = [
    { key: 1, doctorName: 'Doctor D', departmentName: 'Departamento A', externalHospitalName: 'Clínica A', externalDoctorName: 'Doctor externo A', scheduledAt: '09:00' },
    { key: 2, doctorName: 'Doctor E', departmentName: 'Departamento B', externalHospitalName: 'Clínica B', externalDoctorName: 'Doctor externo B', scheduledAt: '09:00' },
    { key: 3, doctorName: 'Doctor F', departmentName: 'Departamento C', externalHospitalName: 'Clínica C', externalDoctorName: 'Doctor externo C', scheduledAt: '09:00' },
    { key: 4, doctorName: 'Doctor G', departmentName: 'Departamento D', externalHospitalName: 'Clínica D', externalDoctorName: 'Doctor externo D', scheduledAt: '09:00' },
    { key: 5, doctorName: 'Doctor H', departmentName: 'Departamento E', externalHospitalName: 'Clínica E', externalDoctorName: 'Doctor externo E', scheduledAt: '09:00' },
    { key: 6, doctorName: 'Doctor I', departmentName: 'Departamento F', externalHospitalName: 'Clínica F', externalDoctorName: 'Doctor externo F', scheduledAt: '09:00' },
    { key: 7, doctorName: 'Doctor J', departmentName: 'Departamento G', externalHospitalName: 'Clínica G', externalDoctorName: 'Doctor externo G', scheduledAt: '09:00' },
    { key: 8, doctorName: 'Doctor K', departmentName: 'Departamento H', externalHospitalName: 'Clínica H', externalDoctorName: 'Doctor externo H', scheduledAt: '09:00' },
    { key: 9, doctorName: 'Doctor L', departmentName: 'Departamento I', externalHospitalName: 'Clínica I', externalDoctorName: 'Doctor externo I', scheduledAt: '09:00' },
    { key: 10, doctorName: 'Doctor M', departmentName: 'Departamento J', externalHospitalName: 'Clínica J', externalDoctorName: 'Doctor externo J', scheduledAt: '09:00' },
    { key: 11, doctorName: 'Doctor N', departmentName: 'Departamento K', externalHospitalName: 'Clínica K', externalDoctorName: 'Doctor externo K', scheduledAt: '09:00' },
    { key: 12, doctorName: 'Doctor Ñ', departmentName: 'Departamento L', externalHospitalName: 'Clínica L', externalDoctorName: 'Doctor externo L', scheduledAt: '09:00' },
    { key: 13, doctorName: 'Doctor O', departmentName: 'Departamento M', externalHospitalName: 'Clínica M', externalDoctorName: 'Doctor externo M', scheduledAt: '09:00' },
    { key: 14, doctorName: 'Doctor P', departmentName: 'Departamento N', externalHospitalName: 'Clínica N', externalDoctorName: 'Doctor externo N', scheduledAt: '09:00' },
    { key: 15, doctorName: 'Doctor Q', departmentName: 'Departamento Ñ', externalHospitalName: 'Clínica Ñ', externalDoctorName: 'Doctor externo Ñ', scheduledAt: '09:00' },
  ]
  const [internalAppointments, setInternalAppointments] = useState(mockData01)
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dayjs())

  const notificationEdit = type => {
    const message = {
      success: 'Éxito',
      error: 'Error',
    }

    const description = {
      success: 'Los datos han sido actualizados correctamente',
      error: 'Ha ocurrido un error. Inténtelo nuevamente',
    }

    notification[type]({
      message: message[type],
      description: description[type],
      duration: 3,
      onClick: () => {
        console.log('Notification Clicked!')
      },
    })
  }


  const notificationDelete = type => {
    const message = {
      success: 'Éxito',
      error: 'Error',
    }

    const description = {
      success: 'Los datos han sido eliminados correctamente',
      error: 'Ha ocurrido un error. Inténtelo nuevamente',
    }

    notification[type]({
      message: message[type],
      description: description[type],
      duration: 3,
      onClick: () => {
        console.log('Notification Clicked!')
      },
    })
  }

  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  const getAppointments = () => {
    switch (getRandomInt(1, 3)) {
      case (1):
        setInternalAppointments(mockData01)
        break
      case (2):
        setInternalAppointments(mockData02)
        break
      case (3):
        setInternalAppointments(mockData03)
        break
      default:
        setInternalAppointments(mockData01)

    }


  }

  const nextDay = () => {
    setSelectedDate(selectedDate.add(1, 'day'))
    getAppointments()
  }

  const previousDay = () => {
    setSelectedDate(selectedDate.subtract(1, 'day'))
    getAppointments()
  }

  const showEditConfirm = internalAppointment => {
    const {
      doctorName,
      departmentName,
      externalHospitalName,
      externalDoctorName,
      scheduledAt,
    } = internalAppointment
    confirm({
      title: '¿Quieres eliminar esta consulta?',
      icon: <ExclamationCircleTwoTone twoToneColor="#F81D22" />,
      content: (
        <>
          <Text strong>Profesional Médico CLC</Text>
          <Input placeholder='Nombre Profesional Médico CLC' value={doctorName} disabled />
          <br />
          <Text strong>Especialidad</Text>
          <Input placeholder='Especialidad' value={departmentName} disabled />
          <br />
          <Text strong>Clínica Externa</Text>
          <Input placeholder='Clínica Externa' value={externalHospitalName} disabled />
          <br />
          <Text strong>Profesional Médico Externo</Text>
          <Input placeholder='Profesional Médico Externo' value={externalDoctorName} disabled />
          <br />
          <Text strong>Fecha</Text>
          <TimePicker
            format={'HH:mm'}
            minuteStep={15}
            placeholder="Hora"
            value={dayjs(scheduledAt)} // invalid date // antd por defecto ocupa moment segun documentacion
            disabled
          />
          <br />
          <Text strong>
            Esta consulta será editada y TEXTO A DEFINIR
          </Text>
        </>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // editInternalAppointment(internalAppointment.key)
        console.log('confirm')
        notificationEdit('success')
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const showDeleteConfirm = internalAppointment => {
    const {
      doctorName,
      departmentName,
      externalHospitalName,
      externalDoctorName,
      scheduledAt,
    } = internalAppointment
    confirm({
      title: '¿Quieres eliminar esta consulta?',
      icon: <ExclamationCircleTwoTone twoToneColor="#F81D22" />,
      content: (
        <>
          <Text strong>Profesional Médico CLC</Text>
          <Text>: {doctorName}</Text>
          <br />
          <Text strong>Especialidad</Text>
          <Text>: {departmentName}</Text>
          <br />
          <Text strong>Clínica Externa</Text>
          <Text>: {externalHospitalName}</Text>
          <br />
          <Text strong>Profesional Médico Externo</Text>
          <Text>: {externalDoctorName}</Text>
          <br />
          <Text strong>Fecha</Text>
          <Text>: {scheduledAt}</Text>
          <br />
          <Text strong>
            Esta consulta será eliminada y TEXTO A DEFINIR
          </Text>
        </>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // deleteInternalAppointment(internalAppointment.key)
        console.log('confirm')
        notificationDelete('success')
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return (
    <>
      <MenuInternalAppointments
        selectedDate={selectedDate}
        previousDay={previousDay}
        nextDay={nextDay}
        history={history}
      />
      <Row style={{ padding: '50px' }}>
        <Col span={24}>
          <InternalAppointmentsTable
            internalAppointments={internalAppointments}
            showEditConfirm={showEditConfirm}
            showDeleteConfirm={showDeleteConfirm}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  )
}
export default withRouter(HomeScreen)
