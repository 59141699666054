import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Appointments from './Appointments'
import InternalAppointments from './InternalAppointments'
import AppLayout from './AppLayout'
import Amplify from 'aws-amplify'
import { LoginScreen } from '@clinicalascondes/clc-parts'
const config = require('App/AmplifyConfig/config')
Amplify.configure(config)

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}

const Screens = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/appointments" />
      <AppLayoutRoute path="/appointments" component={Appointments} />
      <AppLayoutRoute path="/internal-appointments" component={InternalAppointments} />
      <Route
        path="/login"
        component={props => {
          return (
            <LoginScreen label="Call Center" path="appointments" {...props} />
          )
        }}
      />
      <Route component={() => <h1>404</h1>} />
    </Switch>
  )
}

export default Screens
