import { gql } from 'apollo-boost'

const GET_PROFESSIONALS = gql`
  query {
    allProfessionals(pagination: { skip: 0, limit: 1000 }) {
      _id
      firstname
      lastname
    }
  }
`

export { GET_PROFESSIONALS }
