import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  Form,
  Typography,
  Input,
  notification,
  Select,
  DatePicker,
  TimePicker,
  message,
} from 'antd'
import moment from 'moment'
import parseRut from 'App/Helpers/parseRut'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_PROFESSIONALS, FIND_PATIENT } from 'App/Queries'
import {
  CREATE_APPOINTMENT,
  CREATE_PATIENT,
  UPDATE_PATIENT,
} from 'App/Mutators'

const { Title } = Typography
const { Option } = Select

const openNotificationWithIcon = type => {
  const message = {
    success: 'Éxito',
    error: 'Error',
  }

  const description = {
    success: 'Los datos han sido ingresados correctamente',
    error: 'Ha ocurrido un error en el ingreso. Inténtelo nuevamente',
  }

  notification[type]({
    message: message[type],
    description: description[type],
    duration: 3,
    onClick: () => {
      console.log('Notification Clicked!')
    },
  })
}

const parseQueryResponse = response => {
  const { allProfessionals } = response
  const professionals = allProfessionals.map(professional => {
    const { _id, firstname, lastname } = professional
    return {
      value: _id,
      label: `${firstname} ${lastname}`,
    }
  })
  return professionals
}

const buildAppointmentInput = values => {
  const { date, hour, professional, patient, clcAppointmentId } = values
  return {
    input: {
      patient,
      professional,
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
      hour: hour.hour(),
      minute: hour.minute(),
      clcAppointmentId,
    },
  }
}
const buildUpdatePatientInput = (_id, values) => {
  const { rut, passport, firstname, lastname, birthdate, email, phone } = values
  return {
    _id,
    input: {
      rut,
      passport,
      firstname,
      lastname,
      birthdate,
      email,
      phone,
    },
  }
}

const buildCreatePatientInput = values => {
  const { rut, passport, firstname, lastname, birthdate, email, phone } = values
  return {
    input: {
      rut,
      passport,
      firstname,
      lastname,
      birthdate,
      email,
      phone,
    },
  }
}

const CreateScreen = ({ history }) => {
  const [form] = Form.useForm()
  const [professionals, setProfessionals] = useState([])
  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState(null)
  const [typeOfRutOrPassport, setTypeOfRutOrPassport] = useState(true)
  const [rut, setRut] = useState('')
  const [passport, setPassport] = useState('')
  const { data: professionalsData } = useQuery(GET_PROFESSIONALS)
  const { refetch: refetchFindPatient } = useQuery(FIND_PATIENT, {
    variables: { rut, passport },
  })
  const [createPatient] = useMutation(CREATE_PATIENT)
  const [updatePatient] = useMutation(UPDATE_PATIENT)
  const [createAppointment] = useMutation(CREATE_APPOINTMENT)

  if (professionalsData && professionals.length === 0)
    try {
      setProfessionals(parseQueryResponse(professionalsData))
    } catch (error) {
      console.log(error)
    }

  const handleRut = () => {
    setPatient(null)
    form.setFieldsValue({
      rut: parseRut(form.getFieldValue('rut')),
    })
    setRut(form.getFieldValue('rut'))
  }

  const handlePassport = () => {
    setPatient(null)
    setPassport(form.getFieldValue('passport'))
  }

  const findPatient = async () => {
    try {
      const { data } = await refetchFindPatient()
      const { findPatient: patient } = data
      if (patient) {
        setPatient(patient)
        form.setFieldsValue({
          ...patient,
          birthdate: moment(patient.birthdate),
        })
      }
    } catch (error) {
      console.log('paciente no existe', error)
    }
  }

  const onFinish = async values => {
    setLoading(true)
    let patientObj = patient
    if (!patientObj)
      try {
        const { data } = await createPatient({ variables: buildCreatePatientInput(values) })
        const { createPatient: createdPatient } = data
        patientObj = createdPatient

        notification.open({
          message: 'Paciente registrado',
          description: 'Los datos fueron guardados.',
          duration: 5,
        })
      } catch (error) {
        const err = error.toString()
        if (err.includes('Validation failed: phone'))
          message.info('Formato celular incorrecto. Ej. +56912345678')
        else if (err.includes('validation failed: rut'))
          message.info('Rut inválido, inténtelo de nuevo')
        openNotificationWithIcon('error')
        setLoading(false)
      }
    else
      try {
        await updatePatient({
          variables: buildUpdatePatientInput(patient._id, values),
        })
        notification.open({
          message: 'Paciente actualizado',
          description: 'Los datos fueron actualizados.',
          duration: 5,
        })
      } catch (error) {
        const err = error.toString()
        if (err.includes('Validation failed: phone'))
          message.info('Formato celular incorrecto. Ej. +56912345678')
        else if (err.includes('validation failed: rut'))
          message.info('Rut inválido, inténtelo de nuevo')
        openNotificationWithIcon('error')
        setLoading(false)
      }

    if (patientObj) {
      const newAppointment = buildAppointmentInput({
        ...values,
        patient: patientObj._id,
      })

      try {
        await createAppointment({ variables: newAppointment })
        openNotificationWithIcon('success')
        history.push('/appointments')
      } catch (error) {
        console.log(error.toString())
        const err = error.toString()
        if (err.includes('Validation failed: phone'))
          message.info('Formato celular incorrecto. Ej. +56912345678')
        else if (err.includes('validation failed: rut'))
          message.info('Rut inválido, inténtelo de nuevo')
        setLoading(false)
        openNotificationWithIcon('error')
      }
    }
  }

  function disabledDate(current) {
    return (
      current < moment().startOf('day') ||
      current >
        moment()
          .add(90, 'day')
          .endOf('day')
    )
  }

  const validateMessages = {
    required: 'Este campo es requerido!',
    types: {
      email: 'Formato de correo inválido!',
    },
  }

  return (
    <>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Title level={3}>Crear nueva consulta</Title>
        </Col>
      </Row>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        validateMessages={validateMessages}>
        <Row
          justify="center"
          gutter={[16, 8]}
          style={{ marginLeft: '10%', marginRight: '10%' }}>
          <Col span={24}>
            <Title level={4}>Ingresa los datos del paciente</Title>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                marginBottom: '24px',
              }}>
              <div
                style={{
                  width: '100%',
                  color: '#5974af',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '17px',
                }}>
                <span
                  style={{
                    color: '#ff4d4f',
                    marginRight: '4px',
                    marginTop: '2px',
                    fontSize: '18px',
                    lineHeight: '0',
                  }}>
                  *
                </span>
                Tipo de Documento
              </div>
              <Select
                defaultValue="Rut"
                onChange={value => {
                  if (value === 'rut') setTypeOfRutOrPassport(true)
                  else setTypeOfRutOrPassport(false)
                }}
                style={{ width: '100%' }}>
                <Option value="rut">Rut</Option>
                <Option value="passport">Pasaporte</Option>
              </Select>
            </div>
            {typeOfRutOrPassport ? (
              <Form.Item
                name="rut"
                label="Número de Documento"
                rules={[{ required: true }]}>
                <Input
                  placeholder="Ingresar Rut"
                  onChange={handleRut}
                  onBlur={() => findPatient()}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="passport"
                label="Número de Documento"
                rules={[{ required: true }]}>
                <Input
                  placeholder="Ingresar Pasaporte"
                  onChange={handlePassport}
                  onBlur={() => findPatient()}
                />
              </Form.Item>
            )}
            <Form.Item
              name="lastname"
              label="Apellidos"
              rules={[{ required: true }]}>
              <Input placeholder="Apellidos" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Correo"
              rules={[{ required: true, type: 'email' }]}>
              <Input placeholder="Correo" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="firstname"
              label="Nombres"
              rules={[{ required: true }]}>
              <Input placeholder="Nombres" />
            </Form.Item>
            <Form.Item
              name="birthdate"
              label="Fecha de nacimiento"
              rules={[{ required: true }]}>
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Fecha de nacimiento"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Teléfono"
              rules={[{ required: true }]}>
              <Input placeholder="+56912345678" />
            </Form.Item>
            <Form.Item name="clcAppointmentId" label="Número de reserva">
              <Input placeholder="12345678" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4}>Ingresa los datos de la cita</Title>
          </Col>
          <Col span={12}>
            <Form.Item
              name="professional"
              label="Médico"
              rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Seleccione un médico"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }>
                {professionals.map(professional => {
                  return (
                    <Option key={professional.value} value={professional.vale}>
                      {professional.label}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="date" label="Fecha" rules={[{ required: true }]}>
              <DatePicker
                disabledDate={disabledDate}
                placeholder="Fecha"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="hour" label="Hora" rules={[{ required: true }]}>
              <TimePicker
                format={'HH:mm'}
                minuteStep={15}
                placeholder="Hora"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button onClick={() => history.push('/appointments')} block>
              CANCELAR
            </Button>
          </Col>
          <Col span={4}>
            <Button type="primary" htmlType="submit" loading={loading} block>
              CREAR CONSULTA
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default withRouter(CreateScreen)
