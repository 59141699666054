import { gql } from 'apollo-boost'

const GET_APPOINTMENTS = gql`
  query getAppointments($from: Date, $to: Date) {
    allAppointments(pagination: { limit: 250 }, from: $from, to: $to) {
      _id
      scheduledAt
      token
      professional {
        _id
        firstname
        lastname
      }
      patient {
        _id
        rut
        passport
        firstname
        lastname
      }
      closedAt
    }
  }
`
export { GET_APPOINTMENTS }
