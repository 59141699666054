/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import moment from 'moment'

const GetActualDateString = () => {

    const [dateString, setDateString] = useState('')

    const jsonMonths = [
        {
            intMonth: '01',
            strMonth: 'Enero'
        },
        {
            intMonth: '02',
            strMonth: 'Febrero'
        },
        {
            intMonth: '03',
            strMonth: 'Marzo'
        },
        {
            intMonth: '04',
            strMonth: 'Abril'
        },
        {
            intMonth: '05',
            strMonth: 'Mayo'
        },
        {
            intMonth: '06',
            strMonth: 'Junio'
        },
        {
            intMonth: '07',
            strMonth: 'Julio'
        },
        {
            intMonth: '08',
            strMonth: 'Agosto'
        },
        {
            intMonth: '09',
            strMonth: 'Septiembre'
        },
        {
            intMonth: '10',
            strMonth: 'Octubre'
        },
        {
            intMonth: '11',
            strMonth: 'Noviembre'
        },
        {
            intMonth: '12',
            strMonth: 'Diciembre'
        },
    ]

    useEffect(() => {

        const day = moment().format('DD')
        const { strMonth } = jsonMonths.find(obj => obj.intMonth === moment().format('MM'))

        const DateString = `${day} de ${strMonth}`
        setDateString(DateString)

    }, [])

    return (
        <>
            {dateString}
        </>
    )

}

export default GetActualDateString